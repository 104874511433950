
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import FeedbackApi from "@/common/api/auditManage/feedback";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { utils } from "xlsx";

import gallery from "@/components/gallery.vue";

export const pageApiList: any = getPageAPIs(FeedbackApi)

const problemList: any[] = [
  {
    label: '支付问题',
    value: 1
  },
  {
    label: '易用性问题',
    value: 2
  },
  {
    label: 'APP问题',
    value: 3
  },
  {
    label: '服务问题',
    value: 4
  }
]

const TypeText: any = {}

problemList.forEach(({ label, value }) => {
  TypeText[value] = label
})


const defaultForms = {
  images: [],
  feedback_answer_content: ''
}

@Component({
  components: {
    gallery,
  },
  filters: {
    feedBackTypeText(type: number): string {
      if (typeof type != 'number') return ''

      return TypeText[type]
    },
    feedBackStatusText(statusNum: any): any {
      const num2Text: any = {
        0: '未处理',
        1: '已处理'
      }

      return num2Text[statusNum] || '未知状态'
    }
  }
})
export default class FeedbacksList extends Vue {
  private form: any = {
    type:  0, // 问题类型
    is_status: -1, // 状态 
    time: [], // 时段选择
  };

  private deepLoopUtil: any;

  private commonApi: any;
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();


    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    }

    // this.debounceRefreshSingleCardInfoFunc = this.utilIns.debounce.call(
    //   this,
    //   this.refreshSingleCardAccountInfo,
    //   800
    // )
  }

  private forms: any;

  private feedbackApi: any = new FeedbackApi();
  private created(): void {
    // this.getProblemTypes();
    this.search();
  }
  private getTable(): void {
    const form = this.form

    const {
      time,
      type
    } = form

    const startTime = time && time[0] ? Util.dateTimeUtilnohms(this.form.time[0]) : "";
    const endTime =  time && time[1] ? Util.dateTimeUtilnohms(this.form.time[1]) : "";
    this.feedbackApi.getList(
      {
        ...this.form,
        start_date: startTime,
        end_date: endTime,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page;
        this.tablePage.pageSize = res.data.prepage;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }
  private problemList: any[] = [
    ...problemList
  ];

  private statusList: any[] = [
    {
      label: '审核中',
      value: 0
    },
    {
      label: '已处理',
      value: 1
    }
  ]

  // private systemTypaName: any[] = [
  //   '未知',
  //   '安卓',
  //   'IOS',
  //   '小程序'
  // ]
  // private getProblemTypes(): void {
  //   this.feedbackApi.getProblemTypes({}, (res: any) => {
  //     this.problemList = res
  //       ? res.data
  //         ? Array.isArray(res.data.list)
  //           ? res.data.list
  //           : []
  //         : []
  //       : [];
  //   });
  // }
  private dialogFormVisible: boolean = false
  private curFeedBackUserInfo: any = {}

  private onWakeFeedbackDialog(feedbackItem: any) {
    const forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    }

    this.curFeedBackUserInfo = feedbackItem

    this.forms = forms

    this.dialogFormVisible = true
  }


  // 上传媒体文件
  private handleUploadUserPhoto(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "image");
  }

  private async handleUploadUserMedia(
    file: any,
    fileList: any[],
    mode: string
  ) {
    console.log(file, "file");

    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          // let duration = audio.duration;
          console.log(e, "e audio");
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const editForm: any = this.forms;

    const optionList = mode == "image" ? editForm.images : editForm.videos;

    const sendMediaApi =
      mode == "image" ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      const { duration } = await getVideoDuration(file.url).catch(
        (err: any) => {
          this.$message("获取时长失败");
        }
      );

      file.duration = duration;
    }

    optionList.push(file);

    console.log(editForm, "editForm");

    this.$set(this.forms, `${mode}s`, optionList);

    this.changeFilePropByList(file, mode, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.forms, 'avatar_id', id)
        // this.$set(this.forms, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          avatar: url,
        };

        if (file.duration) {
          changeProps.duration = file.duration.toString();
        }

        this.changeFilePropByList(file, mode, changeProps);
      },
      () => {
        this.$message.error("图片上传失败");

        this.changeFilePropByList(file, mode, {
          uploading: false,
          failed: true,
        });
      }
    );
  }

  private changeFilePropByList(file: any, mode: string, changes: any = {}) {
    const editForm: any = this.forms;

    const setList: any[] = mode == "image" ? editForm.images : editForm.videos;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.forms, `${mode}s`, setList);
    }
  }

  // 删除媒体文件
  private handleFileRemove(file: any, mode: string) {
    const forms = this.forms;

    const images = mode == "image" ? forms.images : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const optionList = images.length > 0 ? images : videos;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.forms, `${mode}s`, optionList);
    }
  }

  // 预览媒体文件
  private previewImages: any[] = [];
  private handlePictureCardPreview(file: any, mode: string) {
    const forms = this.forms;

    const images = mode == "image" ? forms.images : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
      ...videos.map((video: any) => {
        return {
          title: "",
          type: "video/mp4",
          href: video.url,
          poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
        };
      }),
    ];

    this.previewImages = urls;
  }

  // 处理
  private handleSubmitFeedbackInfo() {
    const set = Util.setListProp
    const params = this.getFeedbackFormsParams()

    if (typeof params !== 'object') return

    const curFeedBackUserInfo = this.curFeedBackUserInfo 


    set(
      this,
      this.tableData,
      curFeedBackUserInfo,
      "__updateFeedbackStatusLoading",
      true
    );

    this.feedbackApi.updateFeedBackStatus(params, () => {
      set(
        this,
        this.tableData,
        curFeedBackUserInfo,
        "__updateFeedbackStatusLoading",
        false
      );

      this.dialogFormVisible = false

      this.getTable();
    }, () => {
      set(
        this,
        this.tableData,
        curFeedBackUserInfo,
        "__updateFeedbackStatusLoading",
        false
      );
    })

    
  }

  private getFeedbackFormsParams() {
    const params: any = {};

    const curFeedBackUserInfo = this.curFeedBackUserInfo 

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      images,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // machine_number,
      // phone,
      // rank
    } = forms || {};

    const keys = ["feedback_answer_content"];

    const key2EndKey: any = {
      feedback_answer_content: "feedback_answer_content",
   
    };

    const key2ErrMsg: any = {
      feedback_answer_content: "回复不能为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    Object.assign(params, {
      id: curFeedBackUserInfo.id,
    });


    if (images && images.length > 0) {
      params.feedback_answer_pic = images.map((img: any)  => img.fileId).join(',')
    } 
 
   
    return params;
  }

  private feedbackResInfo: any = {
    content: '',
    images: [],
  }

  private dialogFBResFormVisible: boolean = false

  // 获取处理结果
  private async onWakeFeedbackResultDialog(feedbackItem: any) {
    this.curFeedBackUserInfo = feedbackItem

    const {
      feedback_answer_content,
      answer_image_arr
    }  = await (this.refreshFeedbackResInfo() || {}) as any

    const feedbackResInfo = {
      content: feedback_answer_content,
      images: answer_image_arr.map((img: any) => {
        const {
          image_id,
          image_url
        } = img || {}
        // const newImg = {
        //   fileId: image_id,
        //   avatar: image_url,
        // }

        return image_url
      })
    }

    console.log(feedbackResInfo, 'feedbackResInfo')

    this.feedbackResInfo = feedbackResInfo

    this.dialogFBResFormVisible = true
  }

  private refreshFeedbackResInfo() {
    const curFeedBackUserInfo = this.curFeedBackUserInfo
    return new Promise((resolve, reject) => {
      this.feedbackApi.refreshFeedbackResInfo({
        id: curFeedBackUserInfo.id
      }, (res: any) => {
        resolve(res.data || {})
      }, (err: any) => {
        reject(err)
      })
    })
  }



  // 更新状态
  // private handleUpdateFeedBackStatus(row: any) {
  //   const tableData = this.tableData

  //   const existRowIndex = tableData.indexOf(row)

  //   if (existRowIndex > -1) {
  //     row.__updateFeedbackStatusLoading = true

  //     tableData.splice(existRowIndex, 1, row)

  //     this.feedbackApi.updateFeedBackStatus({
  //       id: row.id,
  //       // status: 1
  //     }, () => {
  //       row.__updateFeedbackStatusLoading = false

  //       tableData.splice(existRowIndex, 1, row)

  //       this.getTable();
  //     }, () => {
  //       row.__updateFeedbackStatusLoading = false

  //       tableData.splice(existRowIndex, 1, row)
  //     })
  //   }
  // }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private proofImageUrls(row: any): any[] {
    const {
      image_count,
      images,
      video_count,
      videos
    } = row

    const urls: any[] = []

    if (image_count > 0) {
      urls.push(...images)
    }


    if (video_count > 0) {
      urls.push(...videos.map((video: string) => {
        return `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`
      }))
    }

    return urls
  }

  private images: any = [];
  private handlePreviewMedia(row: any) {
    const {
      image_count,
      images,
      video_count,
      videos
    } = row

    const urls: any[] = []

    if (image_count > 0) {
      urls.push(...images.map((img: string) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img,
          thumbnail: img,
        }
      }))
    }

    if (video_count > 0) {
      urls.push(...videos.map((video: string) => {
        return {
          title: "",
          type: "video/mp4",
          href: video,
          poster: `${video}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`
        }
      }))
    }

    this.images = urls
  }

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // channel渠道字段名 文案对应关系
  // private merchantExcelList2ch: any = {
  //   channelId: '渠道id',
  //   channelName: "渠道名称",
  //   date: "日期",
  //   followNum: "扫码关注数量",
  //   registerNum: "注册数量",
  //   LoginNum: "登录用户数",
  //   completeOrderNum: '完成订单数',
  //   completeOrderAmount: '完成订单金额',
  //   completeOrderPeopleNum: '完成订单人数',
  //   notCompleteNum: '未完成订单数',
  //   notCompleteAmount: '未完成订单金额',
  //   notCompletePeopleNum: '未完成订单数人数',
  //   deviceNum: '新增设备数',
  //   newUserCompleteOrderNum: '新用户完成订单数',
  //   newUserCompleteOrderAmount: '新用户完成订单支付金额',
  //   newUserCompleteOrderPeopleNum: '新用户完成订单人数',
  //   newUserNotCompleteNum: '新用户未完成订单数',
  //   newUserNotCompleteAmount: '新用户未完成订单金额',
  //   newUserNotCompletePeopleNum: '新用户未完成订单人数',
  //   timeoutOrderNum: '超时取消订单数',
  //   timeoutNotPayAmount: '超时未支付金额',
  //   timeoutPeopleNum: '超时取消订单人数',
  //   activeNum: '活跃人数',
  //   retentionPeopleNum: '次日留存人数',
  //   retentionRate: '次日留存率',
  //   totalPaymentPeopleNum: '总付费人数',
  //   totalPaymentRate: '总付费率',
  //   averagePrice: '平均单价',
  //   arpu: 'ARPU',
  //   arppu: 'ARPPU'
  // };
  // // 商户Excel 表头的先后顺序
  // private merchantExcelSortList: string[] = [
  //   "channelId",
  //   "channelName",
  //   "date",
  //   "followNum",
  //   "registerNum",
  //   "LoginNum",
  //   "completeOrderNum",
  //   "completeOrderAmount",
  //   "completeOrderPeopleNum",
  //   "notCompleteNum",
  //   "notCompleteAmount",
  //   "notCompletePeopleNum",
  //   "deviceNum",
  //   "newUserCompleteOrderNum",
  //   "newUserCompleteOrderAmount",
  //   "newUserCompleteOrderPeopleNum",
  //   "newUserNotCompleteNum",
  //   "newUserNotCompleteAmount",
  //   "newUserNotCompletePeopleNum",
  //   "timeoutOrderNum",
  //   "timeoutNotPayAmount",
  //   "timeoutPeopleNum",
  //   "activeNum",
  //   "retentionPeopleNum",
  //   "retentionRate",
  //   "totalPaymentPeopleNum",
  //   "totalPaymentRate",
  //   "averagePrice",
  //   "arpu",
  //   "arppu"
  // ];

  // // 点击导出excel 表格
  // private handleExportExcelChannelList() {
  //   const form = this.form
  //   const {
  //     time,
  //     channelId
  //   } = form

  //   const params: any = {
  //     channelId, 
  //   };
    
  //   if (Array.isArray(time) && time.length === 2) {
  //     params.startDate = Util.dateTimeUtilnohms(this.form.time[0])
  //     params.endDate = Util.dateTimeUtilnohms(this.form.time[1])
  //   } else {
  //     this.$message.error("请选择时段")

  //     return
  //   }

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.feedbackApi.exportExcelChannelList(
  //     params,
  //     (res: any) => {
  //       const { startDate, endDate } = params || {};

  //       const filename = `商户列表 ${startDate}到${endDate}.xlsx`;
  //       const { data: originList } = res || {};

  //       // const {
  //       //   list: originList
  //       // } = data || {}

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             // const { orderNum } = item || {};
  //             const newItem = {
  //               ...(item || {}),
  //             };

  //             for (let key in item) {
  //               if (typeof item[key] == 'number') {
  //                 newItem[key] = item[key].toString()
  //               }
  //             }

  //             // newItem.orderNum =
  //             //   typeof orderNum == "number"
  //             //     ? orderNum.toString()
  //             //     : orderNum || "";

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const merchantExcelSortList = this.merchantExcelSortList;

  //         const merchantExcelList2ch = this.merchantExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           merchantExcelSortList,
  //           merchantExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       // const testData = [
  //       //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
  //       //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
  //       //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
  //       // ]

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */
}
