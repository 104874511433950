import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class FeedbackApi extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/feedback/lists',
            name: 'list',
            label: '列表'
        }],
        ['updateFeedBackStatus',  {
            url: '/admin/feedback/handle',
            name: 'update-status',
            label: '修改意见反馈审核状态'
        }],
        ['refreshFeedbackResInfo', {
            url: '/admin/feedback/info',
            name: 'get-fb-res-info',
            label: '获取意见反馈结果信息'
        }]
    ])
    public getList (params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
 
    // 处理问题状态更新
    public updateFeedBackStatus (params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateFeedBackStatus').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 获取意见反馈结果信息
    public refreshFeedbackResInfo (params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('refreshFeedbackResInfo').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}